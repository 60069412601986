<template>
  <div class="add_editor">
    <el-card>
      <div class="title">{{ archiveId ? '编辑' : '新增' }}运营档案</div>
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="企业头像">
          <div class="cover">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="form.companyLogo"
                @uploadOneImgShow="uploadOneImg"
                @removeImg="removeImg"
              ></img-big-upload>
              <span style="color: #146aff">建议图片整体尺寸为200*200px</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="企业全称" prop="companyFullName">
          <el-select
            @change="handleFullName"
            v-model="form.companyFullName"
            filterable
            remote
            reserve-keyword
            placeholder="请输入企业全称"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.companyFullName"
              :value="item.companyFullName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="群聊名称" prop="wechatGroupName">
          <el-input
            maxlength="30"
            show-word-limit
            v-model="form.wechatGroupName"
            placeholder="请输入群聊名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业简称" prop="companyName">
          <el-input
            maxlength="10"
            show-word-limit
            v-model="form.companyName"
            placeholder="请输入企业简称"
          ></el-input>
        </el-form-item>
        <el-form-item label="核心产品">
          <div style="margin-bottom: 10px" v-for="(item, index) in coreProductList" :key="item.key">
            <el-input v-model="item.name" placeholder="请输入核心产品"></el-input>
            <i class="el-icon-circle-plus add" @click="addCoreProductList"></i>
            <i
              class="el-icon-remove remove"
              v-if="index != 0"
              @click="removeCoreProductList(index)"
            ></i>
          </div>
        </el-form-item>
        <el-form-item label="档案状态" prop="archivesStatusList">
          <el-checkbox-group v-model="form.archivesStatusList">
            <el-checkbox
              :label="item.codeName"
              v-for="(item, index) in filesList"
              :key="index"
            ></el-checkbox> </el-checkbox-group
        ></el-form-item>
        <el-form-item label="运营状态" prop="operationStatusList">
          <el-checkbox-group v-model="form.operationStatusList">
            <el-checkbox
              :label="item.codeName"
              v-for="(item, index) in operateList"
              :key="index"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="拉群原因" prop="buildGroupReason">
          <el-input v-model="form.buildGroupReason" placeholder="请输入拉群原因"></el-input>
        </el-form-item>
        <el-form-item label="线索状态" prop="clueStatus">
          <el-radio-group v-model="form.clueStatus">
            <el-radio
              :label="item.codeName"
              v-for="(item, index) in clueStatusList"
              :key="index"
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="客户评级" prop="companyGrade">
          <el-select clearable v-model="form.companyGrade" placeholder="请选择客户评级程度">
            <el-option label="S级" value="S"></el-option>
            <el-option label="A级" value="A"></el-option>
            <el-option label="B级" value="B"></el-option>
            <el-option label="C级" value="C"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关注方向">
          <div style="margin-bottom: 10px" :key="index" v-for="(item, index) in focusDirectionList">
            <el-input v-model="item.name" placeholder="请输入关注方向"></el-input>
            <i class="el-icon-circle-plus add" @click="addFocusDirectionList"></i>
            <i
              class="el-icon-remove remove"
              v-if="index != 0"
              @click="removeFocusDirectionList(index)"
            ></i>
          </div>
        </el-form-item>
        <el-form-item label="环节转型">
          <div
            style="display: flex; align-items: center"
            v-for="(item, index) in form.linkTransformationList"
            :key="index"
          >
            <div class="form_linkTransformation">
              <el-select
                style="width: 240px; margin-right: 20px"
                clearable
                v-model="item.linkTransformation"
                placeholder="请选择环节"
              >
                <el-option
                  v-for="(li, ind) in linkTransformationLists"
                  :key="ind"
                  :label="li.codeName"
                  :value="li.codeName"
                ></el-option>
              </el-select>
              <el-input v-model="item.productName" placeholder="请输入产品"></el-input>
            </div>
            <i class="el-icon-circle-plus add" @click="addLinkTransformationList"></i>
            <i
              class="el-icon-remove remove"
              v-if="index != 0"
              @click="removeLinkTransformationList(index)"
            ></i>
          </div>
        </el-form-item>
        <div class="title_info">对接人信息</div>
        <div class="form_flex" :key="index" v-for="(item, index) in form.archiveUserList">
          <div class="form_flex_item">
            <span>对接人</span>
            <el-input v-model="item.userName" placeholder="请输入对接人"></el-input>
          </div>
          <div class="form_flex_item">
            <span>职位</span>
            <el-input v-model="item.position" placeholder="请输入职位"></el-input>
          </div>
          <div class="form_flex_item">
            <span>联系方式</span>
            <el-input
              v-model="item.phone"
              @change="handleInputChange(item.phone, index)"
              @input="handleInput(item.phone, index)"
              placeholder="请输入联系方式"
            ></el-input>
          </div>
          <i class="el-icon-circle-plus add" @click="addItemClick"></i>
          <i class="el-icon-remove remove" @click="removeItemClick(index)" v-if="index != 0"></i>
          <el-tag class="tags" type="warning" v-if="item.wechatUserFlag == '1'">智参用户</el-tag>
          <el-tag class="tags" type="warning" v-if="item.wechatUserFlag == '2'">智选用户</el-tag>
        </div>
      </el-form>

      <div class="footer">
        <el-button type="primary" @click="sumbitClick">保存</el-button>
        <el-button @click="routerGo">取消</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
const defaultQueryInfo = Object.freeze({
  archiveUserList: [
    {
      achieveId: '',
      id: '',
      phone: '',
      position: '',
      userId: '',
      userName: '',
      wechatUserFlag: ''
    }
  ],
  archivesStatus: '',
  archivesStatusList: [],
  companyFullName: '',
  companyId: '',
  companyLogo: '',
  companyName: '',
  id: '',
  operationStatus: '',
  operationStatusList: [],
  clueStatus: '',
  phone: '',
  position: '',
  realName: '',
  userId: '',
  companyGrade: '',
  buildGroupReason: '', // 拉群原因
  wechatGroupName: '', // 群聊名称
  focusDirection: '', // 关注方向
  linkTransformation: '', // 环节转化
  coreProductList: [], //核心产品
  focusDirectionList: [], // 关注方向
  linkTransformationList: [
    {
      linkTransformation: '',
      productName: ''
    }
  ]
})

import {
  getArchivesContact,
  updateArchive,
  insertArchive,
  selectById,
  selectByCompanyFullName
} from '@/api/archives.js'
import { getCode } from '@/api/demand.js'
export default {
  name: 'addEditor',
  computed: {
    archiveId() {
      //存在是编辑 不存在为新增
      return this.$route.query.archiveId
    }
  },
  data() {
    return {
      loading: false,
      options: [], //企业选择
      rules: {
        companyName: [{ required: true, message: '请输入企业简称', trigger: 'blur' }],
        wechatGroupName: [{ required: true, message: '请输入群聊名称', trigger: 'blur' }],
        buildGroupReason: [{ required: true, message: '请输入拉群原因', trigger: 'blur' }],
        companyFullName: [{ required: true, message: '请选择企业全称', trigger: 'change' }],
        archivesStatusList: [
          { type: 'array', required: true, message: '请至少选择一个档案状态', trigger: 'change' }
        ],
        operationStatusList: [
          { type: 'array', required: true, message: '请至少选择一个运营状态', trigger: 'change' }
        ],
        clueStatus: [{ required: true, message: '请选择线索状态', trigger: 'change' }],
        companyGrade: [{ required: true, message: '请选择客户评级', trigger: 'change' }]
        // coreProductList: [{ required: true, message: '请输入核心产品', trigger: 'blur' }]
      }, //验证规则
      filesList: [], //档案状态
      operateList: [], //运营状态
      linkTransformationLists: [], //环节转化
      clueStatusList: [], //线索状态
      form: { ...defaultQueryInfo },
      coreProductList: [
        {
          name: ''
        }
      ], //核心产品
      focusDirectionList: [
        {
          name: ''
        }
      ] //关注方向
    }
  },
  created() {
    // 获取档案状态  code 024
    this.getCodeList('024')
    // 获取运营状态  code 025
    this.getCodeList('023')
    // 获取环节转化  code 036
    this.getCodeList('036')
    // 获取线索状态  code 034
    this.getCodeList('034')
    if (this.archiveId) {
      this.getArchiveData() //获取单个数据
    }
    // 解决进入页面会出现上次保存的对接人等信息问题
    this.form.archiveUserList = [
      {
        achieveId: '',
        id: '',
        phone: '',
        position: '',
        userId: '',
        userName: '',
        wechatUserFlag: ''
      }
    ]
    this.coreProductList = [
      {
        name: ''
      }
    ]
    this.focusDirectionList = [
      {
        name: ''
      }
    ]
    this.form.linkTransformationList = [
      {
        linkTransformation: '',
        productName: ''
      }
    ]
  },
  methods: {
    handleInput(val, index) {
      if (val) {
        if (val.length > 11) {
          this.form.archiveUserList[index].phone = val.slice(0, 11)
        } else {
          this.form.archiveUserList[index].phone = val.replace(/[^\d]/g, '')
        }
      }
    },
    // 选择企业全称  联动企业简称和logo
    handleFullName(val) {
      this.options.forEach((el) => {
        if (el.companyFullName === val) {
          this.form.companyLogo = el.companyLogo
          this.form.companyName = el.companyName
          this.form.companyId = el.id
        }
      })
    },
    // 远程搜索
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        const { data: res } = await selectByCompanyFullName({ companyFullName: query })
        if (res.resultCode === 200) {
          this.loading = false
          this.options = res.data
        }
      } else {
        this.options = []
      }
    },
    // 获取单个数据
    async getArchiveData() {
      const { data: res } = await selectById({ id: this.archiveId })
      if (res.resultCode === 200) {
        this.form = res.data
        this.handerData(res.data)
      }
    },
    // 新增编辑接口
    async postAddEdit() {
      let res
      if (this.archiveId) {
        // 编辑
        res = await updateArchive(this.form)
      } else {
        // 新增
        res = await insertArchive(this.form)
      }
      if (res.data.resultCode === 200) {
        this.$message.success(`${this.archiveId ? '编辑' : '新增'}成功`)
        this.form = { ...defaultQueryInfo }
        this.$router.go(-1)
      }
    },
    handerData(res) {
      if (!res.linkTransformationList) {
        this.form.linkTransformationList = [
          {
            linkTransformation: '',
            productName: ''
          }
        ]
      }
      if (res.focusDirectionList) {
        this.focusDirectionList = []
        res.focusDirectionList.forEach((item) => {
          this.focusDirectionList.push({
            name: item
          })
        })
      }
      if (res.coreProductList) {
        this.coreProductList = res.coreProductList.map((item, index) => ({
          name: item,
        }))
      } else {
        this.coreProductList = [
          {
            name: ''
          }
        ]
      }
      if (!res.archiveUserList) {
        this.form.archiveUserList = [
          {
            achieveId: '',
            id: '',
            phone: '',
            position: '',
            userId: '',
            userName: '',
            wechatUserFlag: ''
          }
        ]
      }
    },
    // 数据处理
    dataProcessing() {
      if (this.coreProductList.length > 0) {
        this.form.coreProductList = this.coreProductList.map((item) => {
          return item.name
        })
      }
      if (this.focusDirectionList.length > 0) {
        this.form.focusDirectionList = this.focusDirectionList.map((item) => {
          return item.name
        })
      }
    },
    // 保存
    sumbitClick() {
      let frist = this.form.archiveUserList[0]
      if (frist.userName && frist.position && frist.phone) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.dataProcessing()
            // 验证通过新增编辑接口
            this.postAddEdit()
          } else {
            this.$message.warning('请完善表单信息')
          }
        })
      } else {
        this.$message.warning('请输入对接人、职位、联系方式')
      }
    },
    // 返回
    routerGo() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/operate/archive',
        query: {
          changeNum: true
        }
      })
    },
    // 添加核心产品
    addCoreProductList() {
      this.coreProductList.push({
        name: '',
      })
    },
    // 移除核心产品
    removeCoreProductList(index) {
      this.coreProductList.splice(index, 1)
    },
    // 添加关注方向
    addFocusDirectionList() {
      this.focusDirectionList.push({
        name: ''
      })
    },
    // 移除关注方向
    removeFocusDirectionList(index) {
      this.focusDirectionList.splice(index, 1)
    },
    // 添加环节转化
    addLinkTransformationList() {
      this.form.linkTransformationList.push({
        linkTransformation: '',
        productName: ''
      })
    },
    // 移除环节转化
    removeLinkTransformationList(index) {
      this.form.linkTransformationList.splice(index, 1)
    },
    // 输入完成联系方式调取接口
    async handleInputChange(val, index) {
      const { data: res } = await getArchivesContact({ phone: val })
      if (res.resultCode === 200) {
        this.form.archiveUserList[index].wechatUserFlag = res.data.wechatUserFlag
      }
    },
    // 删除对接人
    removeItemClick(index) {
      this.form.archiveUserList.splice(index, 1)
    },
    // 新增对接人
    addItemClick() {
      this.form.archiveUserList.push({
        achieveId: '',
        id: '',
        phone: '',
        position: '',
        userId: '',
        userName: '',
        wechatUserFlag: ''
      })
    },
    //getCodeList 获取信息
    async getCodeList(code) {
      const { data: res } = await getCode({ codeType: code })
      if (res.resultCode === 200) {
        if (code === '024') {
          this.filesList = res.data
        } else if (code === '023') {
          this.operateList = res.data
        } else if (code === '036') {
          this.linkTransformationLists = res.data
        } else if (code === '034') {
          this.clueStatusList = res.data
        }
      }
    },
    //多个图片上传显示
    uploadOneImg(val) {
      this.form.companyLogo = val
    },
    // 移除
    removeImg(val) {
      this.form.companyLogo = ''
    }
  }
}
</script>

<style lang="less" scoped>
.add_editor {
  width: 100%;
  overflow: hidden;

  ::v-deep .el-select {
    width: 500px;
  }

  ::v-deep .el-input__inner {
    width: 500px;
  }

  .footer {
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 100%;
    overflow: hidden;
    letter-spacing: 2px;
    color: #4e93fb;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .form_flex {
    width: 80%;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    ::v-deep .el-form-item {
      width: 33.3%;
      margin-bottom: 0 !important;

      .el-input__inner {
        width: 100%;
      }
    }

    .add {
      color: #4e93fb;
      font-size: 20px;
      margin-left: 8px;
      cursor: pointer;
    }
    .remove {
      color: #fbb64e;
      cursor: pointer;
      font-size: 20px;
      margin-left: 8px;
    }

    .tags {
      margin-left: 8px;
    }
  }

  .title_info {
    width: 100%;
    overflow: hidden;
    color: #4e93fb;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
  }
}
.cover {
  display: flex;
  flex-direction: column;
}
.thumbnail {
  display: flex;

  color: #bebebe;
}
.thumbnail img {
  width: 200px;
  height: 200px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}
.form_linkTransformation {
  display: flex;
}
::v-deep .form_linkTransformation .el-input {
  width: 240px;
}
::v-deep .form_linkTransformation .el-input__inner {
  width: 240px;
  margin-bottom: 10px;
}
::v-deep .el-input {
  width: 500px;
}
::v-deep .form_flex .el-input {
  width: 300px;
}
::v-deep .form_flex .el-form-item .el-form-item__label {
  width: 70px !important;
}
::v-deep .form_flex .el-form-item .el-form-item__content {
  margin-left: 0 !important;
}
.form_flex .form_flex_item:first-child {
  margin-left: 40px;
}
.form_flex_item {
  // margin-left: 40px;
  font-size: 14px;
  span {
    margin-right: 10px;
  }
}
::v-deep .form_flex_item .el-input__inner {
  width: 260px;
}
.add {
  color: #4e93fb;
  font-size: 20px;
  margin-left: 8px;
  cursor: pointer;
}
.remove {
  color: #fbb64e;
  cursor: pointer;
  font-size: 20px;
  margin-left: 8px;
}
</style>
